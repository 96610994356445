// import firebase from "firebase";
// import "firebase/firestore";

var config = {
  apiKey: "AIzaSyC2Z7R9CtjA2Ls2BLr0MDrEobCC-XMc2Qs",
  authDomain: "biboo-chat.firebaseapp.com",
  databaseURL: "https://biboo-chat.firebaseio.com",
  projectId: "biboo-chat",
  storageBucket: "biboo-chat.appspot.com",
  messagingSenderId: "1057694988065",
  appId: "1:1057694988065:web:8e3c25624d2f8318e13060"
};

var fire = firebase.initializeApp(config);
export default fire;
