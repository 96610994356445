<template>
  <span>404</span> 
</template>

<script>
export default {

}
</script>

<style>

</style>