<template>

      <!-- Login section -->
      <div class="login" v-if="!name">

        <h4>Participa del chat</h4>
        <p>Escribe un nombre</p>
        <input type="text" v-model="userName" />

            <p>Selecciona tu avatar</p>
            <div
              class="avatares"
              v-for="image in images"
              :key="image"
            >
              <label class="radio-container">
                <input name="method" type="radio" class="radio-hidden" :value="image.number" v-model="avatar">
                <img :src="image.url" v-bind:alt="pic" class="radio-image">
              </label>
            </div>
            <br><br><br>
            <!-- <p><span class="btn-login" v-if="username != '' && avatar > 0" @click="updateUsername">Ingresar</span></p> -->
            <button class="btn-login" v-bind:disabled="username != '' && avatar > 0?false:true"  @click="updateUsername">Ingresar</button>
      </div>

      <!-- Chat section -->
      <div class="message-body" v-else>
        <div class="aviso-chat">Últimos 100 mensajes</div>

        <!--  <h5>Welcome {{ name + ':' + avatar}}!</h5> -->
        <div class="scroll-body">


            <div v-for="message in messages" :key="message" class="box-chat">
            <!-- mis mensajes -->
            <div v-if="rmdnb == message.rmdnb" class="soy-yo">
            <!--  <img :src="getImg(message.avatar)" v-bind:alt="pic">
              <b>{{ message.username }}</b> -->
              <span>{{ message.text }}</span>
              <span class="icon-x" @click="deleteMessage(message)"></span>
            </div>
            <!-- otros mensajes -->
            <div v-if="rmdnb != message.rmdnb" class="son-otros">
              <div class="box-image">
              <img :src="getImg(message.avatar)" v-bind:alt="pic">
              </div>
              <div class="box-message">
              <b>{{ message.username }}</b>
              <span>{{ message.text }}</span>
              </div>

            </div>
            </div>

          </div>

        <div class="footer-chat">
        <input v-model="showMessage" v-on:keyup.enter="sendMessage" type="text" class="input-message" />

        <button class="btn-enviar" v-bind:disabled="showMessage != ''?false:true"  @click="sendMessage"><span class="icon-send"></span></button>
        </div>
      </div>

</template>

<script>
import fire from "../fire";
import router from '../router';
export default {

    name: "App",
    data() {
      return {
        userName: "",
        name: null,
        showMessage: "",
        messages: [],
        images:[],
        avatar:0,
        rmdnb: Math.floor(Math.random() * (100000)) + 1
      };
    },
    methods: {
      updateUsername() {
        this.name = this.userName;
        this.userName = "";
      },
      sendMessage() {
        const message = {
          text: this.showMessage,
          username: this.name +'-'+ this.rmdnb,
          avatar:this.avatar
        };

        fire
          .database()
          .ref(this.$route.params.id)
          .push(message);
        this.showMessage = "";
        /* this.name = this.name + this.rmdnb; */
      },
      deleteMessage(message){
        fire.database().ref(this.$route.params.id).child(message['id']).remove();
      },
      getImg(number) {
        for (let image of this.images) {
            if (image.number == number) {
              return image.url;
            }
          }
       },
      getAllImg() {
        for (let image of this.images) {
            if (image.number == number) {
              return image.url;
            }
          }
       }
    },
    mounted() {
      let viewMessage = this;
      const itemsRef = fire.database().ref(this.$route.params.id);
      itemsRef.on("value", snapshot => {
        let data = snapshot.val();
        let messages = [];
        let count = 0;
        Object.keys(data).forEach(key => {
          count++;
          messages.push({
            id: key,
            username: data[key].username.split('-')[0],
            rmdnb: data[key].username.split('-')[1],
            text: data[key].text,
            avatar:data[key].avatar
          });

          if (count>100){
            fire.database().ref(this.$route.params.id).child(messages[0].id).remove();
            messages.shift();
          }

          window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })

        });
        viewMessage.messages = messages;

      });

        let viewImage = this;
        const imgRef = fire.database().ref("Images");
        imgRef.on("value", snapshot => {
          let data = snapshot.val();
          let images = [];
          Object.keys(data).forEach(key => {
            images.push({
              id: key,
              number: data[key].number,
              url: data[key].url
            });
          });
          viewImage.images = images;
        });

      const itemrefChat = fire.database().ref('ChatName');
      itemrefChat.on("value", snapshot => {
        let data = snapshot.val();
        if (data==null) {
          router.push('/404')
        } else {
          let listChats = [];
          Object.keys(data).forEach(key => {
            listChats.push({
              id: key,
              chatName: data[key].chatName,
              date: data[key].text
            });
          });
          let findChat = listChats.find(chat => chat.chatName == this.$route.params.id)
          if(findChat == undefined)
             router.push('/404')
        }
      });
    }
}



</script>

<style>
*{
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
/*  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap");
@font-face {
     font-family: 'tiqueo';
     src: url('../assets/icon.woff') format('woff');
     font-weight: normal;
     font-style: normal;
     font-display: block;
}
 [class^="icon-"], [class*=" icon-"], a:before, i.fa:before{
     font-family: 'tiqueo' !important;
     speak: none;
     font-style: normal;
     font-weight: normal;
     font-variant: normal;
     text-transform: none;
     line-height: 1;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
}

.icon-send:before {
    content: "\e91a";
}
.icon-x:before {
    content: "\e926";
}
span.icon-x {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor:pointer;
}

input, button {
outline: none;
}
.footer-chat {
position: fixed;
bottom: 0;
left:0;
right:0;
max-width: 768px;
margin: auto;
padding: 25px 0 25px 25px;
background:#000;
-webkit-box-shadow: 0px 0px 25px 2px rgba(0,0,0,0.7);
box-shadow: 0px 0px 25px 2px rgba(0,0,0,0.7);

}

.input-message {
width:80%;
float:left;
}

.btn-enviar {
width:20%;
float:left;
background:transparent;
text-align:center;
color:#fff;
border:0;
font-size: 2rem;
}

.btn-enviar span {
line-height:1.4;
cursor:pointer;
}

.aviso-chat {
padding: 20px 20px 40px;
text-align:center;
}

  body {
    background-color: #000;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #fff;
  }


  /* Tamaño del scroll */
  body::-webkit-scrollbar {
    width: 8px;
  }

   /* Estilos barra (thumb) de scroll */
  body::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 4px;
  }

  body::-webkit-scrollbar-thumb:active {
    background-color: #555;
  }

  body::-webkit-scrollbar-thumb:hover {
    background: #555;

  }

   /* Estilos track de scroll */
  body::-webkit-scrollbar-track {
    background: #000;
  }

  body::-webkit-scrollbar-track:hover,
  body::-webkit-scrollbar-track:active {
    background: #000;
  }

  input, textarea {
      display: block;
      width: 100%;
      box-sizing: border-box;
      padding: .6rem;
      font-size: 1.1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  input:focus, textarea:focus {
      background-color: #F3F4F6;
      border: 1px solid #695DFF;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  textarea{
       resize: none;
       overflow: auto;
       height: inherit;
  }

  #app {
    font-family: 'Roboto', sans-serif;
  }

  .login {
    background: #000;
    padding: 25px;
  }

  h4 {
    font-size: 18px;
    text-align: center;
    margin: 0;
    text-transform: uppercase;
    padding: 2rem 2rem 1.5rem;
    font-weight:bold;
  }

  .btn-login {
    color: #fff;
    background: #6e62e2;
    border-color: #6e62e2;
    box-shadow: none;
    display: block;
    width: 100%;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .8125rem 1.25rem;
    font-size: 1.0625rem;
    line-height: 1.6;
    border-radius: .375rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor:pointer;
  }

  .btn-login:hover {
  background: #6459d2;
  }

  button:disabled,
  button[disabled]{
  opacity:0.3;
  }


  /* Seleccion de avatar */
  .radio-image {
  width: 48px;
  box-sizing: initial;
  box-shadow: 0 15px 30px 0 rgba(16,146,147,.12);
  border-radius: 50%;
  transition-duration: .2s;
  cursor: pointer;
  filter: grayscale(1);
  opacity: 0.8;
  }

  .radio-hidden:checked ~ .radio-image {
    filter: grayscale(0);
    transform: scale(1.2);
    opacity: 1;
  }
  .radio-hidden {
    position: absolute;
    visibility: hidden;
    width: 0;
  }
  .radio-image:hover {
    filter: grayscale(0);
    opacity: 1;
  }
  .avatares {
  display:inline-block;
  margin: 5px 10px;
  }

/* Aca comienza el chat */

 .message-body {
   background: #000;
   padding: 25px 25px 90px;
 }

 .box-chat {
     margin: 0 0 25px 0;
 }

.box-chat:after {
  clear:both;
  content:"";
  display: block;
}

.son-otros, .soy-yo {
    max-width: 85%;
}

.soy-yo {
  background: #9047bc;
  float: right;
  color: #fff;
  padding: 15px 40px 15px 15px;
  border-radius: 10px;
  position: relative;
}
.soy-yo span {
word-wrap: break-word;
overflow-wrap: break-word;
}

 .son-otros img, .soy-yo img {
 max-width:48px;
 }

 .box-image {
     position: absolute;
 }

 .box-message {
  margin-left: 58px;
  padding: 15px;
  border-radius: 10px;
  background: #212529;
  color: #fff;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.box-message span {
  display:block;
}


</style>
